<template>
    <b-container>
        <b-row class="text-center mb-3">
            <b-col cols="12">
                <h1>À propos de moi</h1><br />
            </b-col>
        </b-row>
        <b-row align-v="center">
            <b-col xs="12" lg="6">
                <p>
                    Je suis Léa, infirmière puéricultrice depuis 2016, j'ai 
                    exercé en maternité et néonatologie sur Toulouse et ses 
                    alentours pendant 4 ans. Puis j'ai décidé d'élargir mon 
                    expérience, j'ai donc orienté mon choix vers une crèche 
                    associative où j'ai travaillé pendant 1 an et demi.<br />
                    <br />
                    De nombreux parents me posaient des questions sur des sujets
                    très variés comme le sommeil, l'alimentation, le
                    développement de leur enfant, comment se passait le retour à
                    la maison après une hospitalisation en néonatologie ou après
                    le séjour à la maternité. J'ai pu me rendre compte que mon
                    expérience, ma bienveillance, mon écoute et mes conseils
                    étaient très appréciés et surtout demandés.<br />
                    <br />
                    Grâce à tous les questionnements des parents que j'ai pu
                    croiser, j'ai choisi de me former à différentes techniques
                    de bien-être pour vous accompagner, vous, parents, et vos
                    enfants dans votre quotidien.<br />
                    <br />
                    Le respect du rythme et des besoins de l'enfant, la
                    simplicité, la bienveillance et le partage sont des valeurs
                    qui me tiennent à cœur, c'est pour cela que j'ai choisi l'
                    <b-link
                        target="_blank"
                        href="https://www.lecoledubiennaitre.com/"
                    >
                        Ecole du Bien Naitre de Sonia Krief </b-link
                    >, où j'ai suivi des formations et je suis devenue
                    Ambassadrice Bien Naitre.<br />
                    <br />
                    C'est un honneur pour moi de pouvoir être à vos côtés
                    pendant ces moments uniques de vos vies.
                </p>
            </b-col>
            <b-col xs="12" lg="2" offset-lg="1">
                <b-img src="@/assets/about.jpg" style="max-height: 36em"/>
            </b-col>
        </b-row>
        <b-row class="mt-5 text-center">
            <b-col cols="12">
                <non-clickable-img url="training.png" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'About',
    components: { NonClickableImg },
};
</script>

<style scoped>
p {
    text-align: justify;
    text-justify: inter-word;
}
</style>
